<template>
    <v-container>
        <v-alert
            type="info"
            icon="mdi-shield-lock-outline"
            style="margin:0;"
            color="yellow darken-3"
            prominent
            dense
        >
        <v-row>
            <v-col style="display:flex;"><p style="margin:auto;margin-left:0;color:rgba(0,0,0,.75);">{{ message }}</p></v-col>
            <v-col cols="12" sm="auto" style="margin:auto;">
                <v-btn 
                    block
                    @click.stop="videodialog = true"
                    color="secondary"
                ><span style="color:#000;">Watch now</span></v-btn>
                <!-- <template> -->
                    <v-dialog
                        v-model="videodialog"
                        v-if="videodialog"
                        width="max-content"
                    >
                        <!-- <v-row>
                            <v-col

                            > -->
                            <!-- <v-card> -->
                                <video controls style="max-height:85vh;display:block;width:auto;max-width:100%;">
                                    <source src="../assets/videos/tw9CJbvsQZfQ_1280_720_2332.mp4" type="video/mp4">
                                    <source src="https://video.vzaar.com/vzaar/tw9/CJb/target/tw9CJbvsQZfQ_1280_720_2332.mp4?response-content-disposition=inline&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJ74MFWNVAFH6P7FQ%2F20210608%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210608T231635Z&X-Amz-Expires=3660&X-Amz-SignedHeaders=host&X-Amz-Signature=1d4bcba21370a95ac3bd407997913de843300eb84e7b791f528f73ab20432fb3" type="video/mp4">
                                </video>
                            <!-- </v-card> -->
                            <!-- </v-col>
                        </v-row> -->
                    </v-dialog>
                <!-- </template> -->
            </v-col>
        </v-row>
        </v-alert>
    </v-container>
</template>

<script>
  export default {
    name: 'NotificationBanner',

    props: {
      msg: String
    },

    data: () => ({
      message: 'Email phishing is one of the most common methods an attacker can use to gain unauthorised access to our network. Click the button to watch a short video on how to identify phishing emails.',
      videodialog: false
    }),
  }
</script>
