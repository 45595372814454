<template>
  <v-app class="background">
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-container>
        <v-row>
          <v-col cols="9" md="6">
            <div class="d-flex align-center">

              <v-img
                alt="ABCorp"
                class="shrink mt-1"
                contain
                min-width="100"
                src="./assets/images/logo_white.png"
                width="100"
              />

              <h2
                :class="$vuetify.breakpoint.xs ? 'pl-4' : 'pl-8'"
              >Quick Links</h2>

            </div>
          </v-col>
          <v-col cols="3" md="6" align-self="center">
            <div style="width:100%;display:flex;justify-content:flex-end">
              <div>
                <v-switch color="secondary" prepend-icon="mdi-brightness-6" inset v-model="darkmode" @click="darkModeToggle" class="switch-no-message"></v-switch>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <!-- <div class="d-flex align-center">

        <v-img
          alt="ABCorp"
          class="shrink mt-1"
          contain
          min-width="100"
          src="./assets/logo_white.png"
          width="100"
        />

        <h2 class="pl-8">Quick Links</h2>

      </div> -->

      <!-- <v-spacer></v-spacer> -->

    </v-app-bar>

    <v-main class="background">
      <notification-banner/>
      <negative-spacer v-bind:space="4"/>
      <link-tabs v-bind:msg="message" />
    </v-main>

    <v-footer
      
      class="font-weight-medium"
      color="primary"
    >
      <v-col
        class="text-center"
        cols="12"
      >
        <span style="color:#58F2CE;">&copy; {{ new Date().getFullYear() }} — <strong>ABCorp</strong></span>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import LinkTabs from './components/LinkTabs';
import NotificationBanner from './components/NotificationBanner';
import NegativeSpacer from './components/NegativeSpacer';

export default {
  name: 'App',

  components: {
    LinkTabs,
    NotificationBanner,
    NegativeSpacer
  },

  data: () => ({
    message: "Testing",
    darkmode: false,
    // backgroundColor: this.darkmode ? this.$vuetfiy.theme.themes.dark.background : this.$vuetify.theme.themes.light.background
  }),

  methods: {
    reset() {
      this.message = "Testing";
    },
    darkModeToggle() {
      this.$vuetify.theme.dark = this.darkmode;
      localStorage.setItem("darkmode", this.darkmode);
    }
  },

  created() {
    document.title = "Quick Links";
    let darkmode = localStorage.getItem("darkmode");
    if(darkmode !== null && darkmode !== "false") {
      this.darkmode = true;
      this.darkModeToggle();
    }
  },

  mounted() {

  }
};

/**
 * v-bind:msg="message"
 * :msg="message"
 * 
 * v-on:click="reset"
 * @click="reset"
 */
</script>

<style>
.switch-no-message {
  align-items: center;
}
.switch-no-message .v-input__slot {
  margin: 0;
}
.switch-no-message .v-input__control .v-messages {
  display: none !important;
}
</style>