<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/images/logo.png">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <Tile/>
  </div>
</template>

<script>
// @ is an alias to /src
import LinkTabs from '@/components/LinkTabs.vue'
import Tile from '@/components/Tile.vue'

export default {
  name: 'Home',
  components: {
    LinkTabs,
    Tile
  }
}
</script>
