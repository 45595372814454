<template>
    <v-container
        style="width:100%;height:1px;"
        :class="'my-n' + space"
    >
    </v-container>
</template>

<script>
  export default {
    name: 'NegativeSpacer',

    props: {
      space: Number
    },

    data: () => ({
      test: null
    }),
  }
</script>
