import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        // dark: false,
        themes: {
            light: {
                // primary: '#15284d', //old primary colour
                primary: '#000000',
                // secondary: '#ffffff',
                secondary: {
                    base: '#58F2CE',
                    darken1: '#61CE70'
                },
                secondary_dark: '#000000',
                accent: '#ffffff',
                error: '#dd4444',
                background: '#ffffff',
                // anchor: ''
            },
            dark: {
                primary: '#000000',
                secondary: {
                    base: '#58F2CE',
                    darken1: '#61CE70'
                },
                secondary_dark: '#58F2CE',
                accent: '#121212',
                error: '#dd4444',
                background: '#1e1e1e'
            }
        }
    }
});
