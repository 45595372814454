<template>
  <v-container>
    <v-row>
      <v-tabs
        v-model="tab"
        centered
        background-color="rgba(0,0,0,0)"
        color="secondary_dark"
      >
        <v-tab v-for="(tab, i) in tabs" :key="i">
          <span>{{ tab.name }}</span>
        </v-tab>
      </v-tabs>
    </v-row>
    <v-row class="text-center">
      <v-tabs-items
        v-model="tab"
        style="width: 100%; background-color: rgba(0, 0, 0, 0)"
        class="px-3"
      >
        <v-tab-item v-for="(tab, i) in tabs" :key="i">
          <v-row class="text-center">
            <v-col
              v-for="(link, i) in tab.links"
              :key="i"
              cols="12"
              sm="6"
              md="4"
              xl="4"
              class="d-flex"
            >
              <a
                :href="link.href"
                class="text-decoration-none d-flex flex flex-column"
                target="_blank"
                style="height: 100%"
              >
                <v-hover v-slot:default="{ hover }">
                  <v-card
                    :class="{ 'on-hover': hover }"
                    :elevation="hover ? 16 : 4"
                    class="accent"
                    color="secondary"
                    style="height: 100%"
                  >
                    <v-row no-gutters>
                      <v-col cols="3" sm="3" class="px-2 pt-4 pa-sm-4">
                        <v-img
                          :src="link.image"
                          aspect-ratio="1"
                          contain
                          :maxHeight="$vuetify.breakpoint.xs ? '100px' : null"
                        ></v-img>
                      </v-col>
                      <v-col cols="9" sm="9">
                        <v-card-title
                          style="word-break: break-word; text-align: left"
                          >{{ link.text }}</v-card-title
                        >
                        <v-card-subtitle class="text-left">
                          <span v-for="(keyword, i) in link.keywords" :key="i">
                            {{ keyword }}
                            <span v-if="i + 1 !== link.keywords.length">
                              •
                            </span>
                          </span>
                        </v-card-subtitle>
                        <v-card-text class="text-left">
                          <p class="red--text mb-0">{{ link.description }}</p>
                        </v-card-text>
                      </v-col>
                    </v-row>
                    <v-overlay v-if="hover" absolute color="secondary_dark">
                    </v-overlay>
                  </v-card>
                </v-hover>
              </a>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "LinkTabs",

  props: {
    msg: String,
  },

  mounted() {
    this.fetchJson();
  },

  methods: {
    async fetchJson() {
      let json = null;
      await axios.get(this.baseUrl + "links.json").then((response) => {
        // console.log(response.data);
        // json = JSON.parse(response.data);
        json = response.data;
      });
      json.tabs.forEach((tab) => {
        tab.links.forEach(async (link) => {
          // link["image"] = require("../assets/images/" + link.image_file_name);
          link["image"] = this.baseUrl + "images/" + link.image_file_name;
        });
      });
      this.tabs = json.tabs;
    },
  },

  data: () => ({
    json: null,
    baseUrl: process.env.VUE_APP_BASE_URL,
    images: {},
    tab: null,
    tabs: null,
  }),
};
</script>
